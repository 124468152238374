export const SURVEY_ROLES = [
  { value: 'organization_admin', label: 'organization_admin' },
  { value: 'country_admin', label: 'country_admin' },
  { value: 'clinic_admin', label: 'clinic_admin' },
  { value: 'therapist', label: 'therapist' },
  { value: 'patient', label: 'patient' }
];

export const SURVEY_LOCATION = [
  { value: 'rural_area', label: 'rural_area' },
  { value: 'urban_area', label: 'urban_area' },
  { value: 'n/a', label: 'n/a' }
];
